// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*======================= Import ========================*/
/*===================== Variables =======================*/
/*------- GeneralHeader and GeneralFooter fonts ---------*/
/*-------------------------------------------------------*/
/*=======================================================*/
/*=======================================================*/
/*==================== Main styles ======================*/
.about {
  font-family: Montserrat-Light;
  font-size: 20px;
}

.about-text {
  line-height: 1.5;
  padding: 10px;
}

/*=======================================================*/
/*======================= Media =========================*/
@media (max-width: 576px) {
  .about-text {
    text-align: center;
    padding: 10px 0;
  }
}
/*=======================================================*/`, "",{"version":3,"sources":["webpack://./src/components/About/About.scss","webpack://./src/vars.scss"],"names":[],"mappings":"AAAA,0DAAA;ACAA,0DAAA;AAEA,0DAAA;AASA,0DAAA;AAEA,0DAAA;ADTA,0DAAA;AAGA,0DAAA;AAEA;EACI,6BCHS;EDIT,eAAA;AADJ;;AAIA;EACI,gBAAA;EACA,aAAA;AADJ;;AAIA,0DAAA;AAGA,0DAAA;AAEA;EACI;IACI,kBAAA;IACA,eAAA;EAJN;AACF;AAOA,0DAAA","sourcesContent":["/*======================= Import ========================*/\r\n\r\n@import '../../vars';\r\n\r\n/*=======================================================*/\r\n\r\n\r\n/*==================== Main styles ======================*/\r\n\r\n.about {\r\n    font-family: $font-light;\r\n    font-size: 20px;\r\n}\r\n\r\n.about-text {\r\n    line-height: 1.5;\r\n    padding: 10px;\r\n}\r\n\r\n/*=======================================================*/\r\n\r\n\r\n/*======================= Media =========================*/\r\n\r\n@media (max-width: 576px) {\r\n    .about-text {\r\n        text-align: center;\r\n        padding: 10px 0;\r\n    }\r\n}\r\n\r\n/*=======================================================*/","/*===================== Variables =======================*/\r\n\r\n/*------- GeneralHeader and GeneralFooter fonts ---------*/\r\n\r\n$font-bold: Montserrat-Bold;\r\n$font-extra-light-italic: Montserrat-ExtraLightItalic;\r\n$font-extra-light: Montserrat-ExtraLight;\r\n$font-light: Montserrat-Light;\r\n$font-italic: Montserrat-Italic;\r\n$font-regular: Montserrat-Regular;\r\n\r\n/*-------------------------------------------------------*/\r\n\r\n/*=======================================================*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
