// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*======================= Import ========================*/
/*===================== Variables =======================*/
/*------- GeneralHeader and GeneralFooter fonts ---------*/
/*-------------------------------------------------------*/
/*=======================================================*/
/*=======================================================*/
/*==================== Main styles ======================*/
.footer {
  flex: 0 0 auto;
}

.footer-block {
  background: #202020;
  padding: 30px 0px;
  text-align: center;
}

.footer-title {
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 20px;
}

/*=======================================================*/
/*======================= Media =========================*/
@media (max-width: 576px) {
  .footer-title {
    font-size: 18px;
  }
}
/*=======================================================*/`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss","webpack://./src/vars.scss"],"names":[],"mappings":"AAAA,0DAAA;ACAA,0DAAA;AAEA,0DAAA;AASA,0DAAA;AAEA,0DAAA;ADTA,0DAAA;AAGA,0DAAA;AAEA;EACI,cAAA;AADJ;;AAIA;EACI,mBAAA;EACA,iBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,WAAA;EACA,iBAAA;EACA,mBAAA;AADJ;;AAIA,0DAAA;AAGA,0DAAA;AAEA;EACI;IACI,eAAA;EAJN;AACF;AAOA,0DAAA","sourcesContent":["/*======================= Import ========================*/\r\n\r\n@import '../../vars';\r\n\r\n/*=======================================================*/\r\n\r\n\r\n/*==================== Main styles ======================*/\r\n\r\n.footer {\r\n    flex: 0 0 auto;\r\n}\r\n\r\n.footer-block {\r\n    background: #202020;\r\n    padding: 30px 0px;\r\n    text-align: center;\r\n}\r\n\r\n.footer-title {\r\n    color: #fff;\r\n    font-size: 1.4rem;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n/*=======================================================*/\r\n\r\n\r\n/*======================= Media =========================*/\r\n\r\n@media (max-width: 576px) {\r\n    .footer-title {\r\n        font-size: 18px;\r\n    }\r\n}\r\n\r\n/*=======================================================*/","/*===================== Variables =======================*/\r\n\r\n/*------- GeneralHeader and GeneralFooter fonts ---------*/\r\n\r\n$font-bold: Montserrat-Bold;\r\n$font-extra-light-italic: Montserrat-ExtraLightItalic;\r\n$font-extra-light: Montserrat-ExtraLight;\r\n$font-light: Montserrat-Light;\r\n$font-italic: Montserrat-Italic;\r\n$font-regular: Montserrat-Regular;\r\n\r\n/*-------------------------------------------------------*/\r\n\r\n/*=======================================================*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
